import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _04d261b1 = () => interopDefault(import('../pages/agent_root_path/index.vue' /* webpackChunkName: "pages/agent_root_path/index" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _01d08df2 = () => interopDefault(import('../pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _05bf9de5 = () => interopDefault(import('../pages/no-permissions/index.vue' /* webpackChunkName: "pages/no-permissions/index" */))
const _3f04b7c8 = () => interopDefault(import('../pages/versions/index.vue' /* webpackChunkName: "pages/versions/index" */))
const _fb2c2226 = () => interopDefault(import('../pages/108-support/billing.vue' /* webpackChunkName: "pages/108-support/billing" */))
const _6659c7be = () => interopDefault(import('../pages/agent_root_path/error404.vue' /* webpackChunkName: "pages/agent_root_path/error404" */))
const _630bf670 = () => interopDefault(import('../pages/agent_root_path/login.vue' /* webpackChunkName: "pages/agent_root_path/login" */))
const _6caf7239 = () => interopDefault(import('../pages/agent_root_path/my-profile.vue' /* webpackChunkName: "pages/agent_root_path/my-profile" */))
const _d7fb3d4a = () => interopDefault(import('../pages/agents/agent-settlement/index.vue' /* webpackChunkName: "pages/agents/agent-settlement/index" */))
const _b2e6ee10 = () => interopDefault(import('../pages/agents/agents/index.vue' /* webpackChunkName: "pages/agents/agents/index" */))
const _652cbd3a = () => interopDefault(import('../pages/bet/bet-history/index.vue' /* webpackChunkName: "pages/bet/bet-history/index" */))
const _162f64e0 = () => interopDefault(import('../pages/bet/player-bet/index.vue' /* webpackChunkName: "pages/bet/player-bet/index" */))
const _c1e01d9e = () => interopDefault(import('../pages/finance/currencies/index.vue' /* webpackChunkName: "pages/finance/currencies/index" */))
const _1b2ae497 = () => interopDefault(import('../pages/finance/deposits/index.vue' /* webpackChunkName: "pages/finance/deposits/index" */))
const _2915364a = () => interopDefault(import('../pages/finance/exchange-rate.vue' /* webpackChunkName: "pages/finance/exchange-rate" */))
const _6627a4b0 = () => interopDefault(import('../pages/finance/manual-adjustment/index.vue' /* webpackChunkName: "pages/finance/manual-adjustment/index" */))
const _493ea872 = () => interopDefault(import('../pages/finance/withdrawals/index.vue' /* webpackChunkName: "pages/finance/withdrawals/index" */))
const _6847ea8e = () => interopDefault(import('../pages/frontend/cms/index.vue' /* webpackChunkName: "pages/frontend/cms/index" */))
const _22ec7ccd = () => interopDefault(import('../pages/frontend/seo/index.vue' /* webpackChunkName: "pages/frontend/seo/index" */))
const _51cb4d35 = () => interopDefault(import('../pages/marketing-tools/banners/index.vue' /* webpackChunkName: "pages/marketing-tools/banners/index" */))
const _ef6e5d78 = () => interopDefault(import('../pages/marketing-tools/categories/index.vue' /* webpackChunkName: "pages/marketing-tools/categories/index" */))
const _8a0e28c4 = () => interopDefault(import('../pages/marketing-tools/click-record/index.vue' /* webpackChunkName: "pages/marketing-tools/click-record/index" */))
const _73b8c1b7 = () => interopDefault(import('../pages/marketing-tools/marketing-performance/index.vue' /* webpackChunkName: "pages/marketing-tools/marketing-performance/index" */))
const _dccc1232 = () => interopDefault(import('../pages/marketing-tools/view-record/index.vue' /* webpackChunkName: "pages/marketing-tools/view-record/index" */))
const _24cac1a3 = () => interopDefault(import('../pages/operator/ggr-report/index.vue' /* webpackChunkName: "pages/operator/ggr-report/index" */))
const _540a6b2e = () => interopDefault(import('../pages/operator/operator-list/index.vue' /* webpackChunkName: "pages/operator/operator-list/index" */))
const _2cbe4102 = () => interopDefault(import('../pages/operator/operator-report/index.vue' /* webpackChunkName: "pages/operator/operator-report/index" */))
const _4757030c = () => interopDefault(import('../pages/operator/quota/index.vue' /* webpackChunkName: "pages/operator/quota/index" */))
const _af56ca5e = () => interopDefault(import('../pages/player-reward/claim/index.vue' /* webpackChunkName: "pages/player-reward/claim/index" */))
const _53b0c6e7 = () => interopDefault(import('../pages/player-reward/invite-and-earn/index.vue' /* webpackChunkName: "pages/player-reward/invite-and-earn/index" */))
const _3c2b0d78 = () => interopDefault(import('../pages/player-reward/promotion/index.vue' /* webpackChunkName: "pages/player-reward/promotion/index" */))
const _5946c9f8 = () => interopDefault(import('../pages/player-reward/promotion-code/index.vue' /* webpackChunkName: "pages/player-reward/promotion-code/index" */))
const _812c17d6 = () => interopDefault(import('../pages/player-reward/report/index.vue' /* webpackChunkName: "pages/player-reward/report/index" */))
const _bceda820 = () => interopDefault(import('../pages/player-reward/reward-reset-threshold/index.vue' /* webpackChunkName: "pages/player-reward/reward-reset-threshold/index" */))
const _417cc94c = () => interopDefault(import('../pages/player-reward/rolling-settings/index.vue' /* webpackChunkName: "pages/player-reward/rolling-settings/index" */))
const _5324feb6 = () => interopDefault(import('../pages/players/messages/index.vue' /* webpackChunkName: "pages/players/messages/index" */))
const _f7074c18 = () => interopDefault(import('../pages/players/missing-players.vue' /* webpackChunkName: "pages/players/missing-players" */))
const _f18517c2 = () => interopDefault(import('../pages/players/online-players.vue' /* webpackChunkName: "pages/players/online-players" */))
const _6500071e = () => interopDefault(import('../pages/players/players/index.vue' /* webpackChunkName: "pages/players/players/index" */))
const _d667e260 = () => interopDefault(import('../pages/provider/games/index.vue' /* webpackChunkName: "pages/provider/games/index" */))
const _51223571 = () => interopDefault(import('../pages/provider/providers/index.vue' /* webpackChunkName: "pages/provider/providers/index" */))
const _4b655584 = () => interopDefault(import('../pages/reports/company-report.vue' /* webpackChunkName: "pages/reports/company-report" */))
const _25caa852 = () => interopDefault(import('../pages/reports/deposit/index.vue' /* webpackChunkName: "pages/reports/deposit/index" */))
const _00e283f1 = () => interopDefault(import('../pages/reports/report/index.vue' /* webpackChunkName: "pages/reports/report/index" */))
const _2406459c = () => interopDefault(import('../pages/reports/withdrawal/index.vue' /* webpackChunkName: "pages/reports/withdrawal/index" */))
const _619a71a6 = () => interopDefault(import('../pages/setting/admin-risk-control.vue' /* webpackChunkName: "pages/setting/admin-risk-control" */))
const _5007db75 = () => interopDefault(import('../pages/setting/deposit-flow-list.vue' /* webpackChunkName: "pages/setting/deposit-flow-list" */))
const _78c27726 = () => interopDefault(import('../pages/setting/ip-whitelist/index.vue' /* webpackChunkName: "pages/setting/ip-whitelist/index" */))
const _1ccb42d9 = () => interopDefault(import('../pages/setting/language-strings/index.vue' /* webpackChunkName: "pages/setting/language-strings/index" */))
const _78484aa2 = () => interopDefault(import('../pages/setting/languages.vue' /* webpackChunkName: "pages/setting/languages" */))
const _96d8f81e = () => interopDefault(import('../pages/setting/operation-setting.vue' /* webpackChunkName: "pages/setting/operation-setting" */))
const _ae4d5980 = () => interopDefault(import('../pages/setting/operator-custom-domain.vue' /* webpackChunkName: "pages/setting/operator-custom-domain" */))
const _762a86ec = () => interopDefault(import('../pages/setting/player-registration.vue' /* webpackChunkName: "pages/setting/player-registration" */))
const _3ac42d48 = () => interopDefault(import('../pages/setting/promotion-claim-setting.vue' /* webpackChunkName: "pages/setting/promotion-claim-setting" */))
const _db05d91e = () => interopDefault(import('../pages/setting/recaptcha-setting.vue' /* webpackChunkName: "pages/setting/recaptcha-setting" */))
const _6df24b73 = () => interopDefault(import('../pages/setting/suspend-registration.vue' /* webpackChunkName: "pages/setting/suspend-registration" */))
const _845e4740 = () => interopDefault(import('../pages/setting/turnover-setting/index.vue' /* webpackChunkName: "pages/setting/turnover-setting/index" */))
const _4d975e2b = () => interopDefault(import('../pages/setting/website-maintenance/index.vue' /* webpackChunkName: "pages/setting/website-maintenance/index" */))
const _2c66b302 = () => interopDefault(import('../pages/users/roles/index.vue' /* webpackChunkName: "pages/users/roles/index" */))
const _6215d693 = () => interopDefault(import('../pages/users/user-activity/index.vue' /* webpackChunkName: "pages/users/user-activity/index" */))
const _5d46440a = () => interopDefault(import('../pages/users/users/index.vue' /* webpackChunkName: "pages/users/users/index" */))
const _354dca88 = () => interopDefault(import('../pages/agent_root_path/bet/bet-history/index.vue' /* webpackChunkName: "pages/agent_root_path/bet/bet-history/index" */))
const _463075d2 = () => interopDefault(import('../pages/agent_root_path/bet/player-bet/index.vue' /* webpackChunkName: "pages/agent_root_path/bet/player-bet/index" */))
const _012a965e = () => interopDefault(import('../pages/agent_root_path/finance/deposits/index.vue' /* webpackChunkName: "pages/agent_root_path/finance/deposits/index" */))
const _3bcae87e = () => interopDefault(import('../pages/agent_root_path/finance/manual-adjustment/index.vue' /* webpackChunkName: "pages/agent_root_path/finance/manual-adjustment/index" */))
const _7fafcd4b = () => interopDefault(import('../pages/agent_root_path/finance/withdrawals/index.vue' /* webpackChunkName: "pages/agent_root_path/finance/withdrawals/index" */))
const _3924b07d = () => interopDefault(import('../pages/agent_root_path/players/messages/index.vue' /* webpackChunkName: "pages/agent_root_path/players/messages/index" */))
const _5e72e10d = () => interopDefault(import('../pages/agent_root_path/players/missing-players.vue' /* webpackChunkName: "pages/agent_root_path/players/missing-players" */))
const _6d3d25e6 = () => interopDefault(import('../pages/agent_root_path/players/online-players.vue' /* webpackChunkName: "pages/agent_root_path/players/online-players" */))
const _7cef8077 = () => interopDefault(import('../pages/agent_root_path/players/players/index.vue' /* webpackChunkName: "pages/agent_root_path/players/players/index" */))
const _050a2530 = () => interopDefault(import('../pages/agent_root_path/reports/deposit/index.vue' /* webpackChunkName: "pages/agent_root_path/reports/deposit/index" */))
const _2e3c0910 = () => interopDefault(import('../pages/agent_root_path/reports/report/index.vue' /* webpackChunkName: "pages/agent_root_path/reports/report/index" */))
const _52d73939 = () => interopDefault(import('../pages/agent_root_path/reports/withdrawal/index.vue' /* webpackChunkName: "pages/agent_root_path/reports/withdrawal/index" */))
const _cef20928 = () => interopDefault(import('../pages/agent_root_path/users/user-activity/index.vue' /* webpackChunkName: "pages/agent_root_path/users/user-activity/index" */))
const _0fdb5c3a = () => interopDefault(import('../pages/agent_root_path/users/users/index.vue' /* webpackChunkName: "pages/agent_root_path/users/users/index" */))
const _52b8e0c6 = () => interopDefault(import('../pages/agents/agents/create.vue' /* webpackChunkName: "pages/agents/agents/create" */))
const _4508da50 = () => interopDefault(import('../pages/finance/bank-management/bank-accounts/index.vue' /* webpackChunkName: "pages/finance/bank-management/bank-accounts/index" */))
const _f8cc710c = () => interopDefault(import('../pages/finance/bank-management/log.vue' /* webpackChunkName: "pages/finance/bank-management/log" */))
const _20bff3ea = () => interopDefault(import('../pages/finance/bank-management/self-managed-payment-gateway/index.vue' /* webpackChunkName: "pages/finance/bank-management/self-managed-payment-gateway/index" */))
const _ff100d74 = () => interopDefault(import('../pages/finance/cryptocurrency/log.vue' /* webpackChunkName: "pages/finance/cryptocurrency/log" */))
const _2e1dd677 = () => interopDefault(import('../pages/finance/cryptocurrency/wallet-list/index.vue' /* webpackChunkName: "pages/finance/cryptocurrency/wallet-list/index" */))
const _5c285025 = () => interopDefault(import('../pages/finance/deposits/manual-deposit/index.vue' /* webpackChunkName: "pages/finance/deposits/manual-deposit/index" */))
const _46e66902 = () => interopDefault(import('../pages/finance/third-party-payment/log/index.vue' /* webpackChunkName: "pages/finance/third-party-payment/log/index" */))
const _179a7a84 = () => interopDefault(import('../pages/finance/third-party-payment/third-party/index.vue' /* webpackChunkName: "pages/finance/third-party-payment/third-party/index" */))
const _2df1eb17 = () => interopDefault(import('../pages/finance/third-party-payment/third-party-payment-account/index.vue' /* webpackChunkName: "pages/finance/third-party-payment/third-party-payment-account/index" */))
const _6918ba70 = () => interopDefault(import('../pages/frontend/cms/add.vue' /* webpackChunkName: "pages/frontend/cms/add" */))
const _0f52a429 = () => interopDefault(import('../pages/marketing-tools/banners/create.vue' /* webpackChunkName: "pages/marketing-tools/banners/create" */))
const _1112cd75 = () => interopDefault(import('../pages/operator/operator-list/create.vue' /* webpackChunkName: "pages/operator/operator-list/create" */))
const _47c22f25 = () => interopDefault(import('../pages/player-reward/invite-and-earn/referral-rate.vue' /* webpackChunkName: "pages/player-reward/invite-and-earn/referral-rate" */))
const _11728074 = () => interopDefault(import('../pages/player-reward/promotion-code/create.vue' /* webpackChunkName: "pages/player-reward/promotion-code/create" */))
const _70eaec46 = () => interopDefault(import('../pages/player-reward/promotion/create.vue' /* webpackChunkName: "pages/player-reward/promotion/create" */))
const _392f22c8 = () => interopDefault(import('../pages/players/messages/create.vue' /* webpackChunkName: "pages/players/messages/create" */))
const _62b52760 = () => interopDefault(import('../pages/players/players/create.vue' /* webpackChunkName: "pages/players/players/create" */))
const _b75d39f6 = () => interopDefault(import('../pages/setting/language-strings/create.vue' /* webpackChunkName: "pages/setting/language-strings/create" */))
const _777c741f = () => interopDefault(import('../pages/users/roles/create.vue' /* webpackChunkName: "pages/users/roles/create" */))
const _733687f4 = () => interopDefault(import('../pages/users/users/create.vue' /* webpackChunkName: "pages/users/users/create" */))
const _0b13f77e = () => interopDefault(import('../pages/agent_root_path/finance/deposits/manual-deposit/index.vue' /* webpackChunkName: "pages/agent_root_path/finance/deposits/manual-deposit/index" */))
const _1325a9e1 = () => interopDefault(import('../pages/agent_root_path/players/messages/create.vue' /* webpackChunkName: "pages/agent_root_path/players/messages/create" */))
const _48b4d927 = () => interopDefault(import('../pages/agent_root_path/players/players/create.vue' /* webpackChunkName: "pages/agent_root_path/players/players/create" */))
const _31ec353b = () => interopDefault(import('../pages/agent_root_path/users/users/create.vue' /* webpackChunkName: "pages/agent_root_path/users/users/create" */))
const _f8728b24 = () => interopDefault(import('../pages/finance/bank-management/bank-accounts/create.vue' /* webpackChunkName: "pages/finance/bank-management/bank-accounts/create" */))
const _2c150613 = () => interopDefault(import('../pages/finance/bank-management/self-managed-payment-gateway/create.vue' /* webpackChunkName: "pages/finance/bank-management/self-managed-payment-gateway/create" */))
const _855d79b2 = () => interopDefault(import('../pages/finance/cryptocurrency/wallet-list/create.vue' /* webpackChunkName: "pages/finance/cryptocurrency/wallet-list/create" */))
const _90007af2 = () => interopDefault(import('../pages/finance/third-party-payment/third-party-payment-account/create.vue' /* webpackChunkName: "pages/finance/third-party-payment/third-party-payment-account/create" */))
const _8c4c4080 = () => interopDefault(import('../pages/finance/third-party-payment/third-party/create.vue' /* webpackChunkName: "pages/finance/third-party-payment/third-party/create" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _63bcd2fc = () => interopDefault(import('../pages/agent_root_path/bet/bet-history/detail/_id.vue' /* webpackChunkName: "pages/agent_root_path/bet/bet-history/detail/_id" */))
const _5af5e540 = () => interopDefault(import('../pages/agent_root_path/bet/bet-history/sub-bet-history/_id.vue' /* webpackChunkName: "pages/agent_root_path/bet/bet-history/sub-bet-history/_id" */))
const _65761020 = () => interopDefault(import('../pages/agent_root_path/finance/deposits/detail/_id.vue' /* webpackChunkName: "pages/agent_root_path/finance/deposits/detail/_id" */))
const _4b492a34 = () => interopDefault(import('../pages/agent_root_path/users/users/edit/_id.vue' /* webpackChunkName: "pages/agent_root_path/users/users/edit/_id" */))
const _ded1a2b2 = () => interopDefault(import('../pages/finance/bank-management/bank-accounts/edit/_id.vue' /* webpackChunkName: "pages/finance/bank-management/bank-accounts/edit/_id" */))
const _5e81250c = () => interopDefault(import('../pages/finance/bank-management/self-managed-payment-gateway/edit/_id.vue' /* webpackChunkName: "pages/finance/bank-management/self-managed-payment-gateway/edit/_id" */))
const _dcbb25c0 = () => interopDefault(import('../pages/finance/cryptocurrency/wallet-list/edit/_id.vue' /* webpackChunkName: "pages/finance/cryptocurrency/wallet-list/edit/_id" */))
const _e313710e = () => interopDefault(import('../pages/finance/third-party-payment/third-party/edit/_id.vue' /* webpackChunkName: "pages/finance/third-party-payment/third-party/edit/_id" */))
const _178365c0 = () => interopDefault(import('../pages/frontend/cms/submenu/create/_id.vue' /* webpackChunkName: "pages/frontend/cms/submenu/create/_id" */))
const _6881349f = () => interopDefault(import('../pages/agent_root_path/players/players/_id.vue' /* webpackChunkName: "pages/agent_root_path/players/players/_id" */))
const _2234e8d4 = () => interopDefault(import('../pages/agent_root_path/users/user-activity/_id.vue' /* webpackChunkName: "pages/agent_root_path/users/user-activity/_id" */))
const _72c1710b = () => interopDefault(import('../pages/agent_root_path/users/users/_id.vue' /* webpackChunkName: "pages/agent_root_path/users/users/_id" */))
const _6b991eff = () => interopDefault(import('../pages/agents/agents/edit/_id.vue' /* webpackChunkName: "pages/agents/agents/edit/_id" */))
const _ee4b50ae = () => interopDefault(import('../pages/agents/agents/report/_id.vue' /* webpackChunkName: "pages/agents/agents/report/_id" */))
const _366e203b = () => interopDefault(import('../pages/bet/bet-history/detail/_id.vue' /* webpackChunkName: "pages/bet/bet-history/detail/_id" */))
const _677fca50 = () => interopDefault(import('../pages/frontend/cms/create/_id.vue' /* webpackChunkName: "pages/frontend/cms/create/_id" */))
const _4ae5b99e = () => interopDefault(import('../pages/frontend/cms/edit/_id.vue' /* webpackChunkName: "pages/frontend/cms/edit/_id" */))
const _44a2c9f1 = () => interopDefault(import('../pages/frontend/cms/submenu/_id.vue' /* webpackChunkName: "pages/frontend/cms/submenu/_id" */))
const _68cf95a2 = () => interopDefault(import('../pages/marketing-tools/banners/edit/_id.vue' /* webpackChunkName: "pages/marketing-tools/banners/edit/_id" */))
const _401407ae = () => interopDefault(import('../pages/marketing-tools/marketing-performance/associate/_id.vue' /* webpackChunkName: "pages/marketing-tools/marketing-performance/associate/_id" */))
const _3a907fff = () => interopDefault(import('../pages/marketing-tools/marketing-performance/direct/_id.vue' /* webpackChunkName: "pages/marketing-tools/marketing-performance/direct/_id" */))
const _e419964c = () => interopDefault(import('../pages/operator/operator-list/bet-history/_id.vue' /* webpackChunkName: "pages/operator/operator-list/bet-history/_id" */))
const _09aac824 = () => interopDefault(import('../pages/operator/operator-list/edit/_id.vue' /* webpackChunkName: "pages/operator/operator-list/edit/_id" */))
const _06635e98 = () => interopDefault(import('../pages/operator/operator-list/players/_id.vue' /* webpackChunkName: "pages/operator/operator-list/players/_id" */))
const _4eeea8d2 = () => interopDefault(import('../pages/operator/quota/log/_id.vue' /* webpackChunkName: "pages/operator/quota/log/_id" */))
const _75136b02 = () => interopDefault(import('../pages/player-reward/promotion/edit/_id.vue' /* webpackChunkName: "pages/player-reward/promotion/edit/_id" */))
const _6349b2ad = () => interopDefault(import('../pages/users/users/edit/_id.vue' /* webpackChunkName: "pages/users/users/edit/_id" */))
const _4695818e = () => interopDefault(import('../pages/player-reward/invite-and-earn/players/_slug/_ip.vue' /* webpackChunkName: "pages/player-reward/invite-and-earn/players/_slug/_ip" */))
const _37ff51a1 = () => interopDefault(import('../pages/player-reward/invite-and-earn/report/_slug/_ip.vue' /* webpackChunkName: "pages/player-reward/invite-and-earn/report/_slug/_ip" */))
const _6d222b40 = () => interopDefault(import('../pages/player-reward/promotion-code/_id.vue' /* webpackChunkName: "pages/player-reward/promotion-code/_id" */))
const _50813906 = () => interopDefault(import('../pages/players/players/_id.vue' /* webpackChunkName: "pages/players/players/_id" */))
const _0dff86b2 = () => interopDefault(import('../pages/users/roles/_id.vue' /* webpackChunkName: "pages/users/roles/_id" */))
const _483e61bb = () => interopDefault(import('../pages/users/user-activity/_id.vue' /* webpackChunkName: "pages/users/user-activity/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agent_root_path",
    component: _04d261b1,
    name: "agent_root_path"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/my-profile",
    component: _01d08df2,
    name: "my-profile"
  }, {
    path: "/no-permissions",
    component: _05bf9de5,
    name: "no-permissions"
  }, {
    path: "/versions",
    component: _3f04b7c8,
    name: "versions"
  }, {
    path: "/108-support/billing",
    component: _fb2c2226,
    name: "108-support-billing"
  }, {
    path: "/agent_root_path/error404",
    component: _6659c7be,
    name: "agent_root_path-error404"
  }, {
    path: "/agent_root_path/login",
    component: _630bf670,
    name: "agent_root_path-login"
  }, {
    path: "/agent_root_path/my-profile",
    component: _6caf7239,
    name: "agent_root_path-my-profile"
  }, {
    path: "/agents/agent-settlement",
    component: _d7fb3d4a,
    name: "agents-agent-settlement"
  }, {
    path: "/agents/agents",
    component: _b2e6ee10,
    name: "agents-agents"
  }, {
    path: "/bet/bet-history",
    component: _652cbd3a,
    name: "bet-bet-history"
  }, {
    path: "/bet/player-bet",
    component: _162f64e0,
    name: "bet-player-bet"
  }, {
    path: "/finance/currencies",
    component: _c1e01d9e,
    name: "finance-currencies"
  }, {
    path: "/finance/deposits",
    component: _1b2ae497,
    name: "finance-deposits"
  }, {
    path: "/finance/exchange-rate",
    component: _2915364a,
    name: "finance-exchange-rate"
  }, {
    path: "/finance/manual-adjustment",
    component: _6627a4b0,
    name: "finance-manual-adjustment"
  }, {
    path: "/finance/withdrawals",
    component: _493ea872,
    name: "finance-withdrawals"
  }, {
    path: "/frontend/cms",
    component: _6847ea8e,
    name: "frontend-cms"
  }, {
    path: "/frontend/seo",
    component: _22ec7ccd,
    name: "frontend-seo"
  }, {
    path: "/marketing-tools/banners",
    component: _51cb4d35,
    name: "marketing-tools-banners"
  }, {
    path: "/marketing-tools/categories",
    component: _ef6e5d78,
    name: "marketing-tools-categories"
  }, {
    path: "/marketing-tools/click-record",
    component: _8a0e28c4,
    name: "marketing-tools-click-record"
  }, {
    path: "/marketing-tools/marketing-performance",
    component: _73b8c1b7,
    name: "marketing-tools-marketing-performance"
  }, {
    path: "/marketing-tools/view-record",
    component: _dccc1232,
    name: "marketing-tools-view-record"
  }, {
    path: "/operator/ggr-report",
    component: _24cac1a3,
    name: "operator-ggr-report"
  }, {
    path: "/operator/operator-list",
    component: _540a6b2e,
    name: "operator-operator-list"
  }, {
    path: "/operator/operator-report",
    component: _2cbe4102,
    name: "operator-operator-report"
  }, {
    path: "/operator/quota",
    component: _4757030c,
    name: "operator-quota"
  }, {
    path: "/player-reward/claim",
    component: _af56ca5e,
    name: "player-reward-claim"
  }, {
    path: "/player-reward/invite-and-earn",
    component: _53b0c6e7,
    name: "player-reward-invite-and-earn"
  }, {
    path: "/player-reward/promotion",
    component: _3c2b0d78,
    name: "player-reward-promotion"
  }, {
    path: "/player-reward/promotion-code",
    component: _5946c9f8,
    name: "player-reward-promotion-code"
  }, {
    path: "/player-reward/report",
    component: _812c17d6,
    name: "player-reward-report"
  }, {
    path: "/player-reward/reward-reset-threshold",
    component: _bceda820,
    name: "player-reward-reward-reset-threshold"
  }, {
    path: "/player-reward/rolling-settings",
    component: _417cc94c,
    name: "player-reward-rolling-settings"
  }, {
    path: "/players/messages",
    component: _5324feb6,
    name: "players-messages"
  }, {
    path: "/players/missing-players",
    component: _f7074c18,
    name: "players-missing-players"
  }, {
    path: "/players/online-players",
    component: _f18517c2,
    name: "players-online-players"
  }, {
    path: "/players/players",
    component: _6500071e,
    name: "players-players"
  }, {
    path: "/provider/games",
    component: _d667e260,
    name: "provider-games"
  }, {
    path: "/provider/providers",
    component: _51223571,
    name: "provider-providers"
  }, {
    path: "/reports/company-report",
    component: _4b655584,
    name: "reports-company-report"
  }, {
    path: "/reports/deposit",
    component: _25caa852,
    name: "reports-deposit"
  }, {
    path: "/reports/report",
    component: _00e283f1,
    name: "reports-report"
  }, {
    path: "/reports/withdrawal",
    component: _2406459c,
    name: "reports-withdrawal"
  }, {
    path: "/setting/admin-risk-control",
    component: _619a71a6,
    name: "setting-admin-risk-control"
  }, {
    path: "/setting/deposit-flow-list",
    component: _5007db75,
    name: "setting-deposit-flow-list"
  }, {
    path: "/setting/ip-whitelist",
    component: _78c27726,
    name: "setting-ip-whitelist"
  }, {
    path: "/setting/language-strings",
    component: _1ccb42d9,
    name: "setting-language-strings"
  }, {
    path: "/setting/languages",
    component: _78484aa2,
    name: "setting-languages"
  }, {
    path: "/setting/operation-setting",
    component: _96d8f81e,
    name: "setting-operation-setting"
  }, {
    path: "/setting/operator-custom-domain",
    component: _ae4d5980,
    name: "setting-operator-custom-domain"
  }, {
    path: "/setting/player-registration",
    component: _762a86ec,
    name: "setting-player-registration"
  }, {
    path: "/setting/promotion-claim-setting",
    component: _3ac42d48,
    name: "setting-promotion-claim-setting"
  }, {
    path: "/setting/recaptcha-setting",
    component: _db05d91e,
    name: "setting-recaptcha-setting"
  }, {
    path: "/setting/suspend-registration",
    component: _6df24b73,
    name: "setting-suspend-registration"
  }, {
    path: "/setting/turnover-setting",
    component: _845e4740,
    name: "setting-turnover-setting"
  }, {
    path: "/setting/website-maintenance",
    component: _4d975e2b,
    name: "setting-website-maintenance"
  }, {
    path: "/users/roles",
    component: _2c66b302,
    name: "users-roles"
  }, {
    path: "/users/user-activity",
    component: _6215d693,
    name: "users-user-activity"
  }, {
    path: "/users/users",
    component: _5d46440a,
    name: "users-users"
  }, {
    path: "/agent_root_path/bet/bet-history",
    component: _354dca88,
    name: "agent_root_path-bet-bet-history"
  }, {
    path: "/agent_root_path/bet/player-bet",
    component: _463075d2,
    name: "agent_root_path-bet-player-bet"
  }, {
    path: "/agent_root_path/finance/deposits",
    component: _012a965e,
    name: "agent_root_path-finance-deposits"
  }, {
    path: "/agent_root_path/finance/manual-adjustment",
    component: _3bcae87e,
    name: "agent_root_path-finance-manual-adjustment"
  }, {
    path: "/agent_root_path/finance/withdrawals",
    component: _7fafcd4b,
    name: "agent_root_path-finance-withdrawals"
  }, {
    path: "/agent_root_path/players/messages",
    component: _3924b07d,
    name: "agent_root_path-players-messages"
  }, {
    path: "/agent_root_path/players/missing-players",
    component: _5e72e10d,
    name: "agent_root_path-players-missing-players"
  }, {
    path: "/agent_root_path/players/online-players",
    component: _6d3d25e6,
    name: "agent_root_path-players-online-players"
  }, {
    path: "/agent_root_path/players/players",
    component: _7cef8077,
    name: "agent_root_path-players-players"
  }, {
    path: "/agent_root_path/reports/deposit",
    component: _050a2530,
    name: "agent_root_path-reports-deposit"
  }, {
    path: "/agent_root_path/reports/report",
    component: _2e3c0910,
    name: "agent_root_path-reports-report"
  }, {
    path: "/agent_root_path/reports/withdrawal",
    component: _52d73939,
    name: "agent_root_path-reports-withdrawal"
  }, {
    path: "/agent_root_path/users/user-activity",
    component: _cef20928,
    name: "agent_root_path-users-user-activity"
  }, {
    path: "/agent_root_path/users/users",
    component: _0fdb5c3a,
    name: "agent_root_path-users-users"
  }, {
    path: "/agents/agents/create",
    component: _52b8e0c6,
    name: "agents-agents-create"
  }, {
    path: "/finance/bank-management/bank-accounts",
    component: _4508da50,
    name: "finance-bank-management-bank-accounts"
  }, {
    path: "/finance/bank-management/log",
    component: _f8cc710c,
    name: "finance-bank-management-log"
  }, {
    path: "/finance/bank-management/self-managed-payment-gateway",
    component: _20bff3ea,
    name: "finance-bank-management-self-managed-payment-gateway"
  }, {
    path: "/finance/cryptocurrency/log",
    component: _ff100d74,
    name: "finance-cryptocurrency-log"
  }, {
    path: "/finance/cryptocurrency/wallet-list",
    component: _2e1dd677,
    name: "finance-cryptocurrency-wallet-list"
  }, {
    path: "/finance/deposits/manual-deposit",
    component: _5c285025,
    name: "finance-deposits-manual-deposit"
  }, {
    path: "/finance/third-party-payment/log",
    component: _46e66902,
    name: "finance-third-party-payment-log"
  }, {
    path: "/finance/third-party-payment/third-party",
    component: _179a7a84,
    name: "finance-third-party-payment-third-party"
  }, {
    path: "/finance/third-party-payment/third-party-payment-account",
    component: _2df1eb17,
    name: "finance-third-party-payment-third-party-payment-account"
  }, {
    path: "/frontend/cms/add",
    component: _6918ba70,
    name: "frontend-cms-add"
  }, {
    path: "/marketing-tools/banners/create",
    component: _0f52a429,
    name: "marketing-tools-banners-create"
  }, {
    path: "/operator/operator-list/create",
    component: _1112cd75,
    name: "operator-operator-list-create"
  }, {
    path: "/player-reward/invite-and-earn/referral-rate",
    component: _47c22f25,
    name: "player-reward-invite-and-earn-referral-rate"
  }, {
    path: "/player-reward/promotion-code/create",
    component: _11728074,
    name: "player-reward-promotion-code-create"
  }, {
    path: "/player-reward/promotion/create",
    component: _70eaec46,
    name: "player-reward-promotion-create"
  }, {
    path: "/players/messages/create",
    component: _392f22c8,
    name: "players-messages-create"
  }, {
    path: "/players/players/create",
    component: _62b52760,
    name: "players-players-create"
  }, {
    path: "/setting/language-strings/create",
    component: _b75d39f6,
    name: "setting-language-strings-create"
  }, {
    path: "/users/roles/create",
    component: _777c741f,
    name: "users-roles-create"
  }, {
    path: "/users/users/create",
    component: _733687f4,
    name: "users-users-create"
  }, {
    path: "/agent_root_path/finance/deposits/manual-deposit",
    component: _0b13f77e,
    name: "agent_root_path-finance-deposits-manual-deposit"
  }, {
    path: "/agent_root_path/players/messages/create",
    component: _1325a9e1,
    name: "agent_root_path-players-messages-create"
  }, {
    path: "/agent_root_path/players/players/create",
    component: _48b4d927,
    name: "agent_root_path-players-players-create"
  }, {
    path: "/agent_root_path/users/users/create",
    component: _31ec353b,
    name: "agent_root_path-users-users-create"
  }, {
    path: "/finance/bank-management/bank-accounts/create",
    component: _f8728b24,
    name: "finance-bank-management-bank-accounts-create"
  }, {
    path: "/finance/bank-management/self-managed-payment-gateway/create",
    component: _2c150613,
    name: "finance-bank-management-self-managed-payment-gateway-create"
  }, {
    path: "/finance/cryptocurrency/wallet-list/create",
    component: _855d79b2,
    name: "finance-cryptocurrency-wallet-list-create"
  }, {
    path: "/finance/third-party-payment/third-party-payment-account/create",
    component: _90007af2,
    name: "finance-third-party-payment-third-party-payment-account-create"
  }, {
    path: "/finance/third-party-payment/third-party/create",
    component: _8c4c4080,
    name: "finance-third-party-payment-third-party-create"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/agent_root_path/bet/bet-history/detail/:id",
    component: _63bcd2fc,
    name: "agent_root_path-bet-bet-history-detail-id"
  }, {
    path: "/agent_root_path/bet/bet-history/sub-bet-history/:id",
    component: _5af5e540,
    name: "agent_root_path-bet-bet-history-sub-bet-history-id"
  }, {
    path: "/agent_root_path/finance/deposits/detail/:id?",
    component: _65761020,
    name: "agent_root_path-finance-deposits-detail-id"
  }, {
    path: "/agent_root_path/users/users/edit/:id?",
    component: _4b492a34,
    name: "agent_root_path-users-users-edit-id"
  }, {
    path: "/finance/bank-management/bank-accounts/edit/:id?",
    component: _ded1a2b2,
    name: "finance-bank-management-bank-accounts-edit-id"
  }, {
    path: "/finance/bank-management/self-managed-payment-gateway/edit/:id?",
    component: _5e81250c,
    name: "finance-bank-management-self-managed-payment-gateway-edit-id"
  }, {
    path: "/finance/cryptocurrency/wallet-list/edit/:id",
    component: _dcbb25c0,
    name: "finance-cryptocurrency-wallet-list-edit-id"
  }, {
    path: "/finance/third-party-payment/third-party/edit/:id?",
    component: _e313710e,
    name: "finance-third-party-payment-third-party-edit-id"
  }, {
    path: "/frontend/cms/submenu/create/:id?",
    component: _178365c0,
    name: "frontend-cms-submenu-create-id"
  }, {
    path: "/agent_root_path/players/players/:id",
    component: _6881349f,
    name: "agent_root_path-players-players-id"
  }, {
    path: "/agent_root_path/users/user-activity/:id?",
    component: _2234e8d4,
    name: "agent_root_path-users-user-activity-id"
  }, {
    path: "/agent_root_path/users/users/:id",
    component: _72c1710b,
    name: "agent_root_path-users-users-id"
  }, {
    path: "/agents/agents/edit/:id?",
    component: _6b991eff,
    name: "agents-agents-edit-id"
  }, {
    path: "/agents/agents/report/:id?",
    component: _ee4b50ae,
    name: "agents-agents-report-id"
  }, {
    path: "/bet/bet-history/detail/:id",
    component: _366e203b,
    name: "bet-bet-history-detail-id"
  }, {
    path: "/frontend/cms/create/:id?",
    component: _677fca50,
    name: "frontend-cms-create-id"
  }, {
    path: "/frontend/cms/edit/:id?",
    component: _4ae5b99e,
    name: "frontend-cms-edit-id"
  }, {
    path: "/frontend/cms/submenu/:id?",
    component: _44a2c9f1,
    name: "frontend-cms-submenu-id"
  }, {
    path: "/marketing-tools/banners/edit/:id",
    component: _68cf95a2,
    name: "marketing-tools-banners-edit-id"
  }, {
    path: "/marketing-tools/marketing-performance/associate/:id?",
    component: _401407ae,
    name: "marketing-tools-marketing-performance-associate-id"
  }, {
    path: "/marketing-tools/marketing-performance/direct/:id?",
    component: _3a907fff,
    name: "marketing-tools-marketing-performance-direct-id"
  }, {
    path: "/operator/operator-list/bet-history/:id",
    component: _e419964c,
    name: "operator-operator-list-bet-history-id"
  }, {
    path: "/operator/operator-list/edit/:id",
    component: _09aac824,
    name: "operator-operator-list-edit-id"
  }, {
    path: "/operator/operator-list/players/:id",
    component: _06635e98,
    name: "operator-operator-list-players-id"
  }, {
    path: "/operator/quota/log/:id?",
    component: _4eeea8d2,
    name: "operator-quota-log-id"
  }, {
    path: "/player-reward/promotion/edit/:id",
    component: _75136b02,
    name: "player-reward-promotion-edit-id"
  }, {
    path: "/users/users/edit/:id?",
    component: _6349b2ad,
    name: "users-users-edit-id"
  }, {
    path: "/player-reward/invite-and-earn/players/:slug?/:ip?",
    component: _4695818e,
    name: "player-reward-invite-and-earn-players-slug-ip"
  }, {
    path: "/player-reward/invite-and-earn/report/:slug?/:ip?",
    component: _37ff51a1,
    name: "player-reward-invite-and-earn-report-slug-ip"
  }, {
    path: "/player-reward/promotion-code/:id?",
    component: _6d222b40,
    name: "player-reward-promotion-code-id"
  }, {
    path: "/players/players/:id",
    component: _50813906,
    name: "players-players-id"
  }, {
    path: "/users/roles/:id",
    component: _0dff86b2,
    name: "users-roles-id"
  }, {
    path: "/users/user-activity/:id?",
    component: _483e61bb,
    name: "users-user-activity-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
